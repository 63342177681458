export const environment = {
  production: true,
  sentryDsn: "https://53ee68103428e02b8b0e4a75191d8adb@o515678.ingest.us.sentry.io/4507412130496512",
  SENTRY_ORG: "rzp",
  SENTRY_PROJECT: "collinson-pv",
  SENTRY_AUTH_TOKEN: "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",
  corePath: "https://api.poshvine.com/core_service/v1",
  corePathV2: "https://api.poshvine.com/cs/v1/",
  offerPath: "https://api.poshvine.com/offer_service/v1",
  giftPathV2: "https://offers-api.poshvine.com/offer_service/v2/",
  offerPathV2: "https://api.poshvine.com/ps/v1/",
  apiUrlBsV1: "https://api-customer.poshvine.com/bs/v1/",
  apiUrlPaV1: "https://api.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://api.poshvine.com/ls/v1/",
  baseUrl: "https://api.poshvine.com/",
  apiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  stagingUrl: 'https://rewardshub-stage-api.poshvine.com/',
  perksUrl: 'https://sandbox-api.poshvine.com/ps/rewardshub/',
  rewardsHubApiUrl: 'https://rewards-api.poshvine.com/api/v1/',
  analytics: "https://assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-5e1bff55b076.min.js",
  gmApiKey: 'AIzaSyCX4nc8CaDOF9TIWqmKQo48SLo05LOJf3Y',
  secretKey: '000102030405060708090a0b0c0d0e0f',
  ipstackKey: '022375a36968cebce87e10e1c6447ef6',
  clientId: 'd5b4dd8f-bf13-436f-85a4-f75e3def46f9',
  projectId: '2e4ea933-9dbd-48cc-b114-cb69bcc738b0',
  v2ApiBs: 'https://api-customer.poshvine.com/bs/v1/',
  baggagePorterMerchantId: 'e6b983c0-c515-4cf8-a34f-234ffd2d0615',
  apiClientsServiceUrl: "https://api.poshvine.com/cs/v1/",
}
